import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage =
  "https://media.lasvegasweekly.com/img/photos/2021/04/13/0415_Nights_CIRCA_INTERIORS_selects_WV_98flat_t1000.jpg?c76bf34eada957f64a0b14990027a576ff9bf379";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        EliteEdge Advisors
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        style={{ fontWeight: "bold" }}
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        "Gain the Edge with EliteEdge Advisors: Your Winning Play in Sports
        Betting"
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/sign-up/"
        sx={{ minWidth: 200 }}
      >
        Sign Up Today
      </Button>
    </ProductHeroLayout>
  );
}
