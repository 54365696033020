import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DataContext } from "../../../context/DataContext";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

export default function Orders(props) {
  const theme = useTheme();
  const { isLoggedIn, account, isDev } = React.useContext(DataContext);
  const [allSubscriptions, setAllSubscriptions] = React.useState([]);

  const getAllAccountsOrders = async () => {
    try {
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/getAllSubscriptionsAdmin"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/getAllSubscriptionsAdmin"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 400) {
        throw new Error("There was an error with the request");
      } else {
        const data = await response.json();
        setAllSubscriptions(data.allSubscriptions);
      }
    } catch (error) {
      alert(error);
    }
  };

  React.useEffect(() => {
    getAllAccountsOrders();
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        All Orders
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allSubscriptions?.map((row, idx) => (
            <TableRow
              key={idx}
              style={{
                backgroundColor: moment().isBefore(row.endDate)
                  ? theme.palette.secondary.main
                  : "red",
              }}
            >
              <TableCell>{row.email}</TableCell>
              <TableCell>
                {row.product} month{row.product > 1 && "s"}
              </TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.endDate}</TableCell>
              <TableCell align="right">{`$${row.paid}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
