import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

function TodaysBets() {
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } =
    React.useContext(DataContext);
  const [bestBets, setBestBets] = React.useState([]);
  const [todaysBets, setTodaysBets] = React.useState([]);
  const [selectedBets, setSelectedBets] = React.useState([]);
  const [note, setNote] = React.useState([]);
  const [addNote, setAddNote] = React.useState(false);
  const [noteID, setNoteID] = React.useState(null);
  const [hasBeenSent, setHasBeenSent] = React.useState(false);
  const [finalScores, setFinalScores] = React.useState([]);

  const getTodaysBets = async () => {
    const devURL = `http://localhost:3001/elite-edge-advisors/get-all-bets`;
    const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/get-all-bets"}`;
    await fetch(isDev ? devURL : url)
      .then((res) => res.json())
      .then((data) => {
        const { ourBets } = data;
        const filtered = ourBets.map((bet) => {
          return Object.fromEntries(
            Object.entries(bet).filter(([_, v]) => v != null)
          );
        });
        getFinalScores(moment().format("YYYYMMDD"));
        const allBets = filtered
          .filter(
            (bet) =>
              moment(bet.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
          )
          .map((unParsedBet) => {
            setHasBeenSent(unParsedBet.has_been_sent);
            const keys = Object.keys(unParsedBet).filter((key) =>
              key.includes("bet")
            );
            const bets = keys.map((key) => unParsedBet[key]);
            return bets.map((bet) => JSON.parse(bet));
          });
        allBets.length > 0 && setTodaysBets(...allBets);
      });
  };
  const getAllGamesForToday = async () => {
    const parlayURLDev = `http://localhost:3001/parlays`;
    const parlayURL = `https://sheline-art-website-api.herokuapp.com/parlays`;
    await fetch(isDev ? parlayURLDev : parlayURL)
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: TodaysBets.js:60 ~ .then ~ data:", data);
        setBestBets(data.games);
      });
  };

  React.useEffect(() => {
    getAllGamesForToday();
    getTodaysBets();
  }, []);

  React.useEffect(() => {}, [selectedBets]);

  const getFinalScores = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFinalScores(data.events);
      });
  };

  const handleSaveYesterdaysScores = async () => {
    const getMLBGames = async () => {
      return await fetch(
        `https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${moment()
          .subtract(1, "days")
          .format("YYYYMMDD")}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const events = data.events.map((event) => {
            return {
              id: event.id,
              name: event.name,
              homeTeam: {
                id: event.competitions[0].competitors[0].id,
                displayName: event.competitions[0].competitors[0].displayName,
                score: event.competitions[0].competitors[0].score,
                linescores: event.competitions[0].competitors[0].linescores,
                hits: event.competitions[0].competitors[0].hits,
                errors: event.competitions[0].competitors[0].errors,
                records: event.competitions[0].competitors[0].records,
                statistics: event.competitions[0].competitors[0].statistics,
              },
              awayTeam: {
                id: event.competitions[0].competitors[1].id,
                displayName: event.competitions[0].competitors[1].displayName,
                score: event.competitions[0].competitors[1].score,
                linescores: event.competitions[0].competitors[1].linescores,
                hits: event.competitions[0].competitors[1].hits,
                errors: event.competitions[0].competitors[1].errors,
                records: event.competitions[0].competitors[1].records,
                statistics: event.competitions[0].competitors[1].statistics,
              },
            };
          });
          return events;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    try {
      const allGames = await getMLBGames();
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/set-yesterdays-scores"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/set-yesterdays-scores"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          allGames,
          date: moment().subtract(1, "days").format("YYYYMMDD"),
        }),
      });
      if (response.status === 400) {
        throw new Error("Error saving bets");
      } else {
        const data = await response.json();
        // navigate("/account");
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleSendBets = async () => {
    try {
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/send-todays-bet"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/send-todays-bet"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: null,
      });
      if (response.status === 400) {
        throw new Error("Error saving bets");
      } else {
        alert("Bets sent");
        // navigate("/account");
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleSaveBets = async () => {
    const bets = selectedBets.map((bet) => {
      return {
        awayTeam: bet.awayTeam.name,
        homeTeam: bet.homeTeam.name,
        awayTeamAbbr: bet.awayTeam.abbr,
        homeTeamAbbr: bet.homeTeam.abbr,
        date: bet.date,
        espnGameId: bet.espnGameId,
        ourBet: bet.ourBet,
        details: bet.details,
      };
    });

    try {
      const devUrl = `${"http://localhost:3001/elite-edge-advisors/set-todays-bet"}`;
      const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/set-todays-bet"}`;
      const response = await fetch(isDev ? devUrl : url, {
        method: todaysBets.length > 0 ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedBets: bets,
          todaysBetsLength: todaysBets.length,
        }),
      });
      if (response.status === 400) {
        throw new Error("Error saving bets");
      } else {
        getTodaysBets();
        setSelectedBets([]);
        // navigate("/account");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: TodaysBets.js:209 ~ handleSaveBets ~ error:",
        error
      );
      alert(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ marginTop: 20 }}
      >
        Bets For Today
      </Typography>
      {!hasBeenSent && (
        <Typography
          component="h2"
          variant="h4"
          style={{ marginTop: 0, color: "red" }}
        >
          HAVE NOT BEEN SENT OUT
        </Typography>
      )}
      <Grid container spacing={0} style={{}}>
        {todaysBets?.length > 0 &&
          todaysBets.map((bet) => {
            const score = finalScores.find((x) => x.id === bet.espnGameId);
            console.log(
              "🚀 ~ file: TodaysBets.js:218 ~ todaysBets.map ~ score:",
              score
            );
            if (bet.ourBet?.type === "overUnder") {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  spacing={2}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: "1px solid green",
                    padding: "25px 0px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.awayTeam}
                  </p>
                  {" vs "}
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.homeTeam}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome} {bet.ourBet?.details.point} (
                    {bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price})
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                </Grid>
              );
            } else if (bet.ourBet?.type === "spread") {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  spacing={2}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: "1px solid green",
                    padding: "25px 0px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={() => {}}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.details.point > 0 ? "+" : null}
                    {bet.ourBet?.details.point} (
                    {bet.ourBet?.details.price > 0 ? "+" : null}
                    {bet.ourBet?.details.price}){" "}
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                  {addNote && noteID === bet.ourBet?.tempID && (
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      style={{ color: "white", backgroundColor: "white" }}
                      value={note}
                      onChange={(e) => {
                        const value = e.target.value;
                        setNote(value);
                      }}
                    />
                  )}
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  spacing={2}
                  style={{
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: "1px solid green",
                    padding: "25px 0px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={() => {}}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    {bet.ourBet?.outcome}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    ML {bet.ourBet?.details > 0 ? "+" : null}
                    {bet.ourBet?.details}
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                    <p style={{ margin: 0, marginTop: 10 }}>
                      {bet.ourBet?.note}
                    </p>
                  </div>
                </Grid>
              );
            }
          })}
      </Grid>
      {!hasBeenSent && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 300 }}
            onClick={() => {
              handleSendBets();
            }}
          >
            Send Today's Bets
          </Button>
        </div>
      )}
      {selectedBets.length > 0 && (
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          style={{ marginTop: 20 }}
        >
          Current Selected Bets
        </Typography>
      )}{" "}
      {selectedBets.length > 0 && (
        <Grid container spacing={0} style={{}}>
          {selectedBets.length > 0 &&
            selectedBets
              .sort((a, b) => a.ourBet.tempID - b.ourBet.tempID)
              .map((bet) => {
                if (bet.ourBet?.type === "overUnder") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      spacing={2}
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.awayTeam.name}
                      </p>
                      {" vs "}
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.homeTeam.name}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.ourBet?.outcome} {bet.ourBet?.details.point} (
                        {bet.ourBet?.details.price > 0 ? "+" : null}
                        {bet.ourBet?.details.price})
                      </p>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.details?.type.shortDetail.split(" - ")[1]}
                        </p>
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{ color: "white", backgroundColor: "white" }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}
                        >
                          Add Note
                        </Button>
                      )}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}
                        >
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            ),
                          ])
                        }
                      >
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                } else if (bet.ourBet?.type === "spread") {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      spacing={2}
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      onClick={() => {}}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.ourBet?.outcome}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.ourBet?.details.point > 0 ? "+" : null}
                        {bet.ourBet?.details.point} (
                        {bet.ourBet?.details.price > 0 ? "+" : null}
                        {bet.ourBet?.details.price}){" "}
                      </p>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.details?.type.shortDetail.split(" - ")[1]}
                        </p>
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{ color: "white", backgroundColor: "white" }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}
                        >
                          Add Note
                        </Button>
                      )}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}
                        >
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            ),
                          ])
                        }
                      >
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      spacing={2}
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        border: "1px solid green",
                        padding: "25px 0px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      onClick={() => {}}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.8rem",
                          fontWeight: "bold",
                        }}
                      >
                        {bet.ourBet?.outcome}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        ML {bet.ourBet?.details > 0 ? "+" : null}
                        {bet.ourBet?.details}
                      </p>
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.details?.type.shortDetail.split(" - ")[1]}
                        </p>
                        <p style={{ margin: 0, marginTop: 10 }}>
                          {bet.ourBet?.note}
                        </p>
                      </div>
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={4}
                          style={{ color: "white", backgroundColor: "white" }}
                          value={note}
                          onChange={(e) => {
                            const value = e.target.value;
                            setNote(value);
                          }}
                        />
                      )}
                      {!addNote && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            setAddNote(true);
                            setNoteID(bet.ourBet?.tempID);
                          }}
                        >
                          Add Note
                        </Button>
                      )}
                      {addNote && noteID === bet.ourBet?.tempID && (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          component="a"
                          sx={{ marginTop: 2 }}
                          onClick={() => {
                            const betToUpdate = selectedBets.find(
                              (x) => x.ourBet.tempID === bet.ourBet?.tempID
                            );
                            betToUpdate.ourBet.note = note;
                            const restOfBets = selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            );
                            setSelectedBets([...restOfBets, betToUpdate]);
                            setNote("");
                            setAddNote(false);
                          }}
                        >
                          Save Note
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component="a"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          setSelectedBets([
                            ...selectedBets.filter(
                              (x) => x.ourBet.tempID !== bet.ourBet?.tempID
                            ),
                          ])
                        }
                      >
                        Remove Bet
                      </Button>
                    </Grid>
                  );
                }
              })}
        </Grid>
      )}
      {selectedBets.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignContent: "center",
            textAlign: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 300 }}
            onClick={() => {
              handleSaveBets();
            }}
          >
            Save Current Selected Bets
          </Button>
        </div>
      )}
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ marginTop: 20 }}
      >
        All Games Today
      </Typography>
      <Grid container spacing={0} style={{ marginTop: 0 }}>
        {bestBets?.length > 0
          ? bestBets.map((bet) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  spacing={3}
                  style={{
                    padding: "25px 0px",
                    cursor: "pointer",
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 20,
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 0 }}>
                      {bet.details?.type.shortDetail.split(" - ")[1]}
                    </p>
                  </div>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox style={{ margin: 0, padding: 0 }} />
                          }
                          onClick={() =>
                            setSelectedBets([
                              ...selectedBets,
                              {
                                ourBet: {
                                  tempID: selectedBets.length + 1,
                                  outcome: bet.awayTeam.name,
                                  type: "moneyline",
                                  note: "",
                                  details: bet.awayTeam.odds.moneyline,
                                },
                                ...bet,
                              },
                            ])
                          }
                          style={{ margin: 0, padding: 0 }}
                          label={
                            bet.awayTeam.odds.moneyline > 0
                              ? "+" + bet.awayTeam.odds.moneyline
                              : bet.awayTeam.odds.moneyline
                          }
                        />
                        {bet.awayTeam.odds.spread?.point && (
                          <FormControlLabel
                            control={
                              <Checkbox style={{ margin: 0, padding: 0 }} />
                            }
                            onClick={() =>
                              setSelectedBets([
                                ...selectedBets,
                                {
                                  ourBet: {
                                    tempID: selectedBets.length + 1,
                                    note: "",
                                    outcome: bet.awayTeam.name,
                                    type: "spread",
                                    details: bet.awayTeam.odds.spread,
                                  },
                                  ...bet,
                                },
                              ])
                            }
                            style={{ margin: 0, padding: 0 }}
                            label={`${bet.awayTeam.odds.spread?.point} (${
                              bet.awayTeam.odds.spread?.price > 0 ? "+" : ""
                            }${bet.awayTeam.odds.spread?.price})`}
                          />
                        )}
                      </FormGroup>
                      <p style={{ lineHeight: 1, margin: 0, fontSize: 18 }}>
                        {bet.awayTeam.name}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Streak:{" "}
                        {bet.awayTeam.currentStreak > 0
                          ? "W" + bet.awayTeam.currentStreak
                          : "L" +
                            bet.awayTeam.currentStreak.toString().split("-")[1]}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Overall: {bet.awayTeam.overallRecord}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Away: {bet.awayTeam.awayRecord}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Last 10: n/a
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Last 20: n/a
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        vs {bet.homeTeam.name}:{" "}
                        {bet.awayTeam.recordVsOpponent?.overall.win} -{" "}
                        {bet.awayTeam.recordVsOpponent?.overall.loss}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: 17,
                          marginTop: 2,
                        }}
                      >
                        {bet.awayTeam.startingPitcher.name}
                      </p>
                      <p
                        style={{
                          lineHeight: 1,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        {bet.awayTeam.startingPitcher.wins}-
                        {bet.awayTeam.startingPitcher.losses}{" "}
                        {bet.awayTeam.startingPitcher.ERA} ERA
                      </p>
                      <p
                        style={{
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Last 3 Games
                      </p>
                      {bet.awayTeam.startingPitcher?.stats
                        ?.slice(-3)
                        .reverse()
                        .map((game, idx) => {
                          return (
                            <p
                              key={idx}
                              style={{
                                lineHeight: 1.5,
                                margin: 0,
                                fontSize: "0.7rem",
                                marginTop: 2,
                                color:
                                  game.stat.wins > 0
                                    ? "green"
                                    : game.stat.losses > 0
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {moment(game.date).format("MMM D")}:{" "}
                              {game.stat.wins > 0
                                ? "W"
                                : game.stat.losses > 0
                                ? "L"
                                : "ND"}{" "}
                              {game.stat.summary}
                            </p>
                          );
                        })}
                      <p
                        style={{
                          lineHeight: 1.2,
                          margin: 0,
                          fontSize: "0.9rem",
                          marginTop: 2,
                        }}
                      >
                        Against {bet.homeTeam.name}
                      </p>
                      {bet.awayTeam.startingPitcher?.stats
                        ?.filter(
                          (game) => game.opponent.name === bet.homeTeam.name
                        )
                        ?.map((game, idx) => {
                          return (
                            <p
                              key={idx}
                              style={{
                                lineHeight: 1.5,
                                margin: 0,
                                fontSize: "0.7rem",
                                marginTop: 2,
                                color:
                                  game.stat.wins > 0
                                    ? "green"
                                    : game.stat.losses > 0
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {moment(game.date).format("MMM D")}:{" "}
                              {game.stat.wins > 0
                                ? "W"
                                : game.stat.losses > 0
                                ? "L"
                                : "ND"}{" "}
                              {game.stat.summary}
                            </p>
                          );
                        })}
                    </div>
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox style={{ margin: 0, padding: 0 }} />
                          }
                          onClick={() =>
                            setSelectedBets([
                              ...selectedBets,
                              {
                                ourBet: {
                                  tempID: selectedBets.length + 1,
                                  note: "",
                                  outcome: "over",
                                  type: "overUnder",
                                  details: bet.overUnder?.find(
                                    (x) => x.name === "Over"
                                  ),
                                },
                                ...bet,
                              },
                            ])
                          }
                          style={{ margin: 0, padding: 0 }}
                          label={`o${
                            bet.overUnder?.find((x) => x.name === "Over").point
                          } (${
                            bet.overUnder?.find((x) => x.name === "Over")
                              .price > 0
                              ? "+"
                              : ""
                          }${
                            bet.overUnder?.find((x) => x.name === "Over").price
                          })`}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox style={{ margin: 0, padding: 0 }} />
                          }
                          onClick={() =>
                            setSelectedBets([
                              ...selectedBets,
                              {
                                ourBet: {
                                  tempID: selectedBets.length + 1,
                                  note: "",
                                  outcome: "under",
                                  type: "overUnder",
                                  details: bet.overUnder?.find(
                                    (x) => x.name === "Under"
                                  ),
                                },
                                ...bet,
                              },
                            ])
                          }
                          style={{ margin: 0, padding: 0 }}
                          label={`u${
                            bet.overUnder?.find((x) => x.name === "Under").point
                          } (${
                            bet.overUnder?.find((x) => x.name === "Under")
                              .price > 0
                              ? "+"
                              : ""
                          }${
                            bet.overUnder?.find((x) => x.name === "Under").price
                          })`}
                        />
                      </FormGroup>
                    </div>
                    <div
                      style={{
                        fontFamily: "Play",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Play",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox style={{ margin: 0, padding: 0 }} />
                            }
                            onClick={() =>
                              setSelectedBets([
                                ...selectedBets,
                                {
                                  ourBet: {
                                    tempID: selectedBets.length + 1,
                                    note: "",
                                    outcome: bet.homeTeam.name,
                                    type: "moneyline",
                                    details: bet.homeTeam.odds.moneyline,
                                  },
                                  ...bet,
                                },
                              ])
                            }
                            style={{ margin: 0, padding: 0 }}
                            label={
                              bet.homeTeam.odds.moneyline > 0
                                ? "+" + bet.homeTeam.odds.moneyline
                                : bet.homeTeam.odds.moneyline
                            }
                          />
                          {bet.homeTeam.odds.spread?.point && (
                            <FormControlLabel
                              control={
                                <Checkbox style={{ margin: 0, padding: 0 }} />
                              }
                              onClick={() =>
                                setSelectedBets([
                                  ...selectedBets,
                                  {
                                    ourBet: {
                                      tempID: selectedBets.length + 1,
                                      outcome: bet.homeTeam.name,
                                      type: "spread",
                                      note: "",
                                      details: bet.homeTeam.odds.spread,
                                    },
                                    ...bet,
                                  },
                                ])
                              }
                              style={{ margin: 0, padding: 0 }}
                              label={`${bet.homeTeam.odds.spread?.point} (${
                                bet.homeTeam.odds.spread?.price > 0 ? "+" : ""
                              }${bet.homeTeam.odds.spread?.price})`}
                            />
                          )}
                        </FormGroup>
                        <p style={{ lineHeight: 1, margin: 0, fontSize: 18 }}>
                          {bet.homeTeam.name}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Streak:{" "}
                          {bet.homeTeam.currentStreak > 0
                            ? "W" + bet.homeTeam.currentStreak
                            : "L" +
                              bet.homeTeam.currentStreak
                                .toString()
                                .split("-")[1]}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Overall: {bet.homeTeam.overallRecord}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Home: {bet.homeTeam.awayRecord}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Last 10: n/a
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Last 20: n/a
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          vs {bet.awayTeam.name}:{" "}
                          {bet.homeTeam.recordVsOpponent?.overall.win} -{" "}
                          {bet.homeTeam.recordVsOpponent?.overall.loss}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: 17,
                            marginTop: 2,
                          }}
                        >
                          {bet.homeTeam.startingPitcher.name}
                        </p>
                        <p
                          style={{
                            lineHeight: 1,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          {bet.homeTeam.startingPitcher.wins}-
                          {bet.homeTeam.startingPitcher.losses}{" "}
                          {bet.homeTeam.startingPitcher.ERA} ERA
                        </p>
                        <p
                          style={{
                            lineHeight: 1.2,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Last 3 Games
                        </p>
                        {bet.homeTeam.startingPitcher?.stats
                          ?.slice(-3)
                          .reverse()
                          .map((game, idx) => {
                            return (
                              <p
                                key={idx}
                                style={{
                                  lineHeight: 1.5,
                                  margin: 0,
                                  fontSize: "0.7rem",
                                  marginTop: 2,
                                  color:
                                    game.stat.wins > 0
                                      ? "green"
                                      : game.stat.losses > 0
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {moment(game.date).format("MMM D")}:{" "}
                                {game.stat.wins > 0
                                  ? "W"
                                  : game.stat.losses > 0
                                  ? "L"
                                  : "ND"}{" "}
                                {game.stat.summary}
                              </p>
                            );
                          })}
                        <p
                          style={{
                            lineHeight: 1.2,
                            margin: 0,
                            fontSize: "0.9rem",
                            marginTop: 2,
                          }}
                        >
                          Against {bet.awayTeam.name}
                        </p>
                        {bet.homeTeam.startingPitcher?.stats
                          ?.filter(
                            (game) => game.opponent.name === bet.awayTeam.name
                          )
                          ?.map((game, idx) => {
                            return (
                              <p
                                key={idx}
                                style={{
                                  lineHeight: 1.5,
                                  margin: 0,
                                  fontSize: "0.7rem",
                                  marginTop: 2,
                                  color:
                                    game.stat.wins > 0
                                      ? "green"
                                      : game.stat.losses > 0
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {moment(game.date).format("MMM D")}:{" "}
                                {game.stat.wins > 0
                                  ? "W"
                                  : game.stat.losses > 0
                                  ? "L"
                                  : "ND"}{" "}
                                {game.stat.summary}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })
          : null}
      </Grid>
    </div>
  );
}

export default TodaysBets;
