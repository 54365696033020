import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Field, Form, FormSpy } from "react-final-form";
import { DataContext } from "../../../context/DataContext";
import Typography from "../../components/Typography";
import AppFooter from "../../views/AppFooter";
import AppAppBar from "../../views/AppAppBar";
import AppForm from "../../views/AppForm";
import { email, required } from "../../form/validation";
import RFTextField from "../../form/RFTextField";
import FormButton from "../../form/FormButton";
import FormFeedback from "../../form/FormFeedback";
import { useNavigate } from "react-router-dom";
import Orders from "./Orders";
import TodaysBets from "./TodaysBets";
import ProductValues from "../../views/ProductValues";
import moment from "moment";

function Account() {
  const navigate = useNavigate();
  const [sent, setSent] = React.useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] =
    React.useState(false);
  const { isLoggedIn, account } = React.useContext(DataContext);

  React.useEffect(() => {
    console.log("🚀 ~ file: index.js:30 ~ React.useEffect ~ account:", account);
    if (!isLoggedIn || !account) {
      navigate("/");
    } else {
      console.log(account);
      const currentSubscription = account.subscriptions?.find((x) =>
        moment().isBefore(x.endDate)
      );
      if (currentSubscription) {
        setHasActiveSubscription(true);
      } else {
        setHasActiveSubscription(false);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <AppAppBar />
      {hasActiveSubscription && <TodaysBets />}
      {!hasActiveSubscription && (
        <>
          <Typography
            variant="h3"
            component="h3"
            gutterBottom
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            No Active Subscription
          </Typography>
          <ProductValues />
        </>
      )}
      <Orders subscriptions={account?.subscriptions} />
      <AppFooter />
    </React.Fragment>
  );
}

export default Account;
