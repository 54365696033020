import React, { useState } from "react";
export const DataContext = React.createContext();

export default function MyProvider(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [account, setAccount] = useState(null);
  const [isDev, setIsDev] = useState(false);

  return (
    <DataContext.Provider
      value={{
        isDev,
        setIsDev,
        isLoggedIn,
        setIsLoggedIn,
        account,
        setAccount,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
