import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      EliteEdge Advisors LLC {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "secondary.main",
  mr: 1,
  "&:hover": {
    bgcolor: "secondary.dark",
  },
  borderRadius: "20%",
};

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container
        sx={{ my: 8, display: "flex" }}
        style={{ justifyContent: "center" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="a"
                  href="https://www.instagram.com/eliteedgeadvisors"
                  sx={iconStyle}
                >
                  <InstagramIcon style={{ fontSize: 48 }} />
                </Box>
              </Grid>
              <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={6} sm={6} md={6}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/premium-themes/onepirate/terms/">Terms</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/premium-themes/onepirate/privacy/">Privacy</Link>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Typography>
  );
}
