import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, useTheme } from "@mui/material";
import moment from "moment";

function TodaysBets() {
  const theme = useTheme();
  console.log("🚀 ~ file: TodaysBets.js:10 ~ TodaysBets ~ theme:", theme);
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } =
    React.useContext(DataContext);
  const [todaysDate, setTodaysDate] = React.useState(
    moment().format("MMMM DD YYYY")
  );
  const [todaysBetsDate, setTodaysBetsDate] = React.useState();
  const [todaysBets, setTodaysBets] = React.useState([]);
  const [selectedBets, setSelectedBets] = React.useState([]);

  const getTodaysBets = async () => {
    const parlayDevURL = `http://localhost:3001/elite-edge-advisors/todays-bets`;
    const parlayURL = `https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/todays-bets`;
    await fetch(isDev ? parlayDevURL : parlayURL)
      .then((res) => res.json())
      .then((data) => {
        const allBets = [];
        Object.keys(data).forEach(
          (key) =>
            key.includes("bet") &&
            data[key] &&
            allBets.push(JSON.parse(data[key]))
        );
        setTodaysBetsDate(moment(data.date.split("T")[0]).format("M-DD-YYYY"));
        setTodaysBets(allBets.filter((bet) => bet.did_we_bet_game && bet));
      });
  };

  React.useEffect(() => {
    getTodaysBets();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="primary"
        style={{ marginTop: 20 }}
      >
        {todaysBetsDate}
      </Typography>
      <Grid container spacing={0} style={{ marginTop: 0 }}>
        {todaysBets.length < 1 && (
          <Typography>No bets yet. Check back later!</Typography>
        )}
        {todaysBets.length > 0
          ? todaysBets.map((bet) => {
              console.log(
                "🚀 ~ file: TodaysBets.js:66 ~ ?todaysBets.map ~ bet:",
                bet
              );
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  spacing={2}
                  style={{
                    padding: "25px 0px",
                    fontFamily: "Play",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    backgroundColor: theme.palette.secondary.main,
                    color:
                      bet.pointDiff > 4 || bet.pointDiff < 3
                        ? "black"
                        : "black",
                  }}
                  onClick={() => {
                    if (!selectedBets.includes(bet))
                      setSelectedBets([...selectedBets, bet]);
                  }}
                >
                  <p style={{ margin: 0, fontSize: 20, fontWeight: "bold" }}>
                    {bet.bet}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {bet.bet === bet.awayTeam.name
                      ? bet.awayOdds
                      : bet.homeOdds}
                  </p>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ lineHeight: 0.4, margin: 0, fontSize: 15 }}>
                      {bet.awayTeam.name}
                    </p>
                    <p style={{ lineHeight: 0.4, margin: 10 }}>at</p>
                    <p style={{ lineHeight: 0.4, margin: 0, fontSize: 15 }}>
                      {bet.homeTeam.name}
                    </p>
                  </div>
                  <div
                    style={{
                      fontFamily: "Play",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p style={{ margin: 0, marginTop: 10, fontSize: 20 }}>
                      {bet.details.type?.shortDetail?.split(" - ")[1]}
                    </p>
                  </div>
                </Grid>
              );
            })
          : null}
      </Grid>
    </div>
  );
}

export default TodaysBets;
